// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/auth.login/route.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/auth.login/route.jsx");
}
// REMIX HMR END

import { useState } from "react";
import { json } from "@remix-run/node";
import { AppProvider as PolarisAppProvider, Button, Card, FormLayout, Page, Text, TextField } from "@shopify/polaris";
import { Form, useActionData, useLoaderData } from "@remix-run/react";
import polarisStyles from "@shopify/polaris/build/esm/styles.css";
import { login } from "../../shopify.server";
import { loginErrorMessage } from "./error.server";
export const links = () => [{
  rel: "stylesheet",
  href: polarisStyles
}];
export const loader = async ({
  request
}) => {
  const errors = loginErrorMessage(await login(request));
  return json({
    errors,
    polarisTranslations: require(`@shopify/polaris/locales/en.json`)
  });
};
export const action = async ({
  request
}) => {
  const errors = loginErrorMessage(await login(request));
  return json({
    errors
  });
};
export default function Auth() {
  _s();
  const loaderData = useLoaderData();
  const actionData = useActionData();
  const [shop, setShop] = useState("");
  const {
    errors
  } = actionData || loaderData;
  return <PolarisAppProvider i18n={loaderData.polarisTranslations}>
      <Page>
        <Card>
          <Form method="post">
            <FormLayout>
              <Text variant="headingMd" as="h2">
                Log in
              </Text>
              <TextField type="text" name="shop" label="Shop domain" helpText="example.myshopify.com" value={shop} onChange={setShop} autoComplete="on" error={errors.shop} />

              <Button submit>Log in</Button>
            </FormLayout>
          </Form>
        </Card>
      </Page>
    </PolarisAppProvider>;
}
_s(Auth, "Hygkc0EhEXxwK+5D3KbBFFhIADs=", false, function () {
  return [useLoaderData, useActionData];
});
_c = Auth;
var _c;
$RefreshReg$(_c, "Auth");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;